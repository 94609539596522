<template>
  <div>
    <div class="pc">
      <home-pc></home-pc>
    </div>
    <div class="mobile">
      <home-mobile></home-mobile>
    </div>
  </div>
</template>

<script>
  import HomePc from "./HomePc";
  import HomeMobile from "./HomeMobile";
  export default {
    name: "Home",
    components: {HomeMobile, HomePc},
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>

</style>
