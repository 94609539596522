<template>
  <div style="margin-top:60px">
    <div class="banner">
      <div class="size-32 weight-300">
        웹/앱 플랫폼 창업도<span class="primary">.</span><br />
        쇼핑몰 창업처럼
      </div>
      <div class="mo-pagetitle-bold">누구나 쉽고 간단하게</div>
    </div>
    <div class="banner-white-box">
      수 많은 사람들이 시도하고 있는 플랫폼 서비스 창업,<br />
      왜 아직도
      <span class="primary"
        >모든 예비 창업자들이 처음부터<br />
        동일한 시행착오를 반복</span
      >해야 하는 걸까요?<br />
      <img src="/static/icon/ic_arrow-down.svg" alt="" style="width:24px;margin-top:16px" />
    </div>

    <div class="section-menu">
      <div class="mo-section-title-bold main text-center margin-bottom-20">
        내 창업단계에 맞는 <br />
        상품으로 시작하세요.
      </div>
      <div class="grid-menu">
        <div v-for="(item, idx) in menu" :key="`menu-${idx}`" class="item-menu unselect" @click="clickMenu(item)">
          <!--<img :src="`/static/icon/m_${item.icon}.svg`">-->
          <svg-icon class="svg-icon" :icon="item.icon" color="sub2" :width="32" :height="32"></svg-icon>
          <div class="size-13 sub">{{ item.name }}</div>
        </div>
      </div>
      <div class="flex-align fixed-menu" v-show="vFixedMenu">
        <div class="flex-align drag-scroll-mobile">
          <div
            v-for="(item, idx) in menu"
            :key="'section-item-' + idx"
            :id="`fixed-item-${idx}`"
            class="item-fixed-menu subtitle7"
            :class="`${selectedMenu !== item.id ? 'sub' : 'selected'}`"
            @click="clickMenu(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <a class="anchor" id="menu-m-1"></a>
    </div>
    <div id="container-1" class="menus section">
      <div class="text-center">
        <img src="/static/icon/lightbulb_on.svg" />
        <div class="body3 primary" style="margin:6px 0 8px 0">아이디어 테스트 단계</div>
        <div class="mo-section-title-bold text-center" style="word-break: keep-all;">
          1개월 단위로 플랫폼 서비스를 운영해보고 싶은 창업자를 위한
          <span class="primary">플랫폼파이 <br />무료 체험해보세요!</span>
        </div>
        <div class="body4 sub3 text-center" style="margin:8px 0 28px 0;word-break: keep-all;">
          블럭 단위로 디자인 편집을 할 수 있는 빌더가 포함되어 있어 간단히 내 아이디어에 맞게 설정하고 런칭할 수 있어요.
        </div>
      </div>

      <card-product
        style="margin-top:20px"
        device="mobile"
        isTheme
        v-for="theme in themes"
        :key="`theme-${theme.id}`"
        :card="theme"
      ></card-product>
    </div>
    <div class="margin-top-50 platformfy-info-container">
      <div class="text-center">
        <div class="body0 main" style="line-height: 32px">이미 많은 고객분들께서 플랫폼파이로</div>
        <div class="body0-bold main" style="line-height: 32px">
          초기 아이디어를 검증하고,<br />투자를 유치하고 계십니다!
        </div>
      </div>
      <div style="margin: 28px 0; overflow: hidden">
        <img src="/static/img/home/info_img_full.png" style="height: 220px" />
      </div>
      <div class="flex-justify">
        <a href="https://platformfy.com/theme_market" target="_blank">
          <button
            class="button is-main body2-medium flex-align"
            style="background-color: transparent; gap:10px;width:280px;height:48px"
          >
            테마 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
          </button>
        </a>
      </div>
    </div>
    <div class="position-relative">
      <a class="anchor" id="menu-m-2"></a>
    </div>
    <div id="container-2" class="menus section">
      <div class="mo-section-title-bold text-center">
        <img src="/static/icon/fi_flag_primary.svg" />
        <div class="body3 primary" style="margin:6px 0 8px 0">시장 선점 단계</div>
        <div class="sub">
          원하는 대로 수정제작을 하고 싶은<br />창업자를 위한 <span class="primary">제작 스토어</span>
        </div>
        <div class="body4 sub3 margin-top-20" style="word-break: keep-all;">
          유저 니즈에 맞게 부분 수정 개발을 해서 런칭할 수 있어요. <br />
          플랫폼파이 테마로 먼저 테스트 하고 추가 개발 하는 것도 가능해요.
        </div>
      </div>

      <card-product
        style="margin-top:20px"
        device="mobile"
        v-for="skin in skins"
        :key="`skin-${skin.id}`"
        :card="skin"
      ></card-product>

      <div class="margin-top-20 flex-center">
        <button
          class="button is-main body2-medium flex-align"
          style="gap:10px;width:280px;height:48px"
          @click="routerPush('/skin_market')"
        >
          스킨 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
        </button>
      </div>
    </div>
    <div class="position-relative">
      <a class="anchor" id="menu-m-3"></a>
    </div>
    <div id="container-3" class="menus section">
      <div class="img-prime-back"></div>
      <div class="position-relative" style="z-index:1">
        <img src="/static/icon/chart_mixed.svg" />
        <div class="body2-medium primary" style="margin-bottom:8px">비즈니스 단계</div>
        <div class="mo-section-title-bold white">완전 맞춤제작을 원하신다면?</div>
        <div class="size-28 primary weight-700 margin-top-4">Prime</div>
        <div class="body4 sub5" style="margin:8px 0 28px 0">
          이미 매출이 발생하고 있는 비즈니스를 온라인 서비스로<br />
          런칭할 때 이용해요. 높은 완성도가 무엇보다도 중요해요.
        </div>
        <slick ref="slick" :options="slickOptions" @afterChange="onChange">
          <div v-for="prime in primes" :key="`prime-ex-${prime.id}`">
            <div class="slick-img" :style="`background-image:url(/static/img/prime-portfolio${prime.id}.png)`"></div>
            <div class="flex-center" v-if="prime.url !== ''">
              <a :href="prime.url" target="_blank">
                <div class="flex margin-top-20 margin-bottom-20 primary">
                  사이트 바로가기 <i class="material-icons">keyboard_arrow_right</i>
                </div>
              </a>
            </div>
          </div>
        </slick>
        <div class="indicators">
          <div
            class="indicator unselect"
            :class="{ 'indicator-current': idx === currentSlide }"
            @click="$refs.slick.goTo(idx)"
            v-for="(prime, idx) in primes"
            :key="`indicator-${prime.id}`"
          ></div>
        </div>
        <div class="flex" style="margin-top:36px;gap:12px">
          <button class="button is-primary-light" style="flex:1;height:44px" @click="routerPush('/prime')">
            자세히 보기
          </button>
          <button class="button is-primary" style="flex:1;height:44px" @click="routerPush('/prime')">신청하기</button>
        </div>
      </div>
    </div>

    <div class="menus section">
      <div class="body0-medium main margin-bottom-12" style="word-break: keep-all">
        런치팩 솔루션은 일반적으로 많이 사용되는 기술스펙으로 구성되어 있습니다.
      </div>
      <div class="body3 sub3 margin-bottom-28">
        언제든 고도화가 가능하고 투자유치나 사업규모 확장으로 독립이 필요한 경우 별도 계약을 통해 프론트엔드 소스코드
        공유 및 백엔드 API 전송이 가능합니다.
      </div>
      <img src="/static/img/home/mo-img_tech.png" />
    </div>

    <div class="position-relative">
      <a class="anchor" id="menu-m-4"></a>
    </div>
    <div id="container-4" class="menus section text-center">
      <div class="box-app-market-desc">
        <img src="/static/icon/rocket.svg" />
        <div class="body3 primary" style="margin:6px 0 8px 0">서비스 런칭 단계</div>
        <div class="mo-section-title-bold sub">
          런칭 준비를 마치셨다면 <br />
          앱포팅 서비스로 내 앱을 <br />
          구글플레이, 앱스토어에 올려보세요!
        </div>
        <div class="body4 sub3">
          앱포팅 및 앱마켓 등록 대행 부터 GA(Google Analytics)등록 등 비즈니스 운영에 필요한 다양한 부가서비스를 활용해
          내 서비스를 본격적으로 사업화 할 수 있습니다.
        </div>

        <div class="margin-top-28 flex-center">
          <button
            class="button is-primary-light body2-medium flex-align"
            style="width:180px;height:48px"
            @click="routerPush('additional_service')"
          >
            부가서비스 더보기 <img src="/static/icon/u_arrow-right.svg" style="margin-left:8px" class="svg-primary" />
          </button>
        </div>
      </div>
      <div class="menu4-items">
        <card-product-simple v-for="card in plugins" :key="`plugin-${card.id}`" :card="card"></card-product-simple>
      </div>
    </div>
    <div class="position-relative">
      <a class="anchor" id="menu-m-5"></a>
    </div>
    <div id="container-5" class="menus section" style="padding-bottom:0">
      <div class="text-center">
        <img src="/static/icon/fi_tool_primary.svg" alt="" />
        <div class="body3 primary" style="margin-bottom:8px">인하우스 제작 단계</div>
        <div class="size-20 weight-700">
          디자이너, 개발자를 위한 <br /><span class="primary">웹/앱 템플릿 소스 스토어</span>
        </div>
        <div class="body4 sub3 margin-top-8">
          직접 제작할 수 있는 제작자나 개발팀이 있는 <br />창업팀이 이용할 수 있어요
        </div>
      </div>
      <card-product
        style="margin-top:20px"
        device="mobile"
        v-for="template in templates"
        :key="`template-${template.id}`"
        :card="template"
      ></card-product>

      <div class="margin-top-20 flex-center">
        <button
          class="button is-main body2-medium flex-align"
          style="gap:10px;width:280px;height:48px"
          @click="routerPush('/template_market')"
        >
          템플릿 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
        </button>
      </div>
    </div>
    <div class="position-relative">
      <a class="anchor" id="menu-m-6"></a>
    </div>
    <div id="container-6" class="menus" style="padding-top:50px">
      <div class="menu6 unselect" @click="routerPush('/template_market')">
        <!-- <img src="/static/icon/fi_x-octagon_fill.svg">
        <div class="body3 main" style="margin:8px 0 12px 0">비지니스 종료 단계</div> -->
        <img src="/static/img/img_gold_2.png" style="width: 190px; height: 138px" />
        <div class="body2 main">대표님! 예산을 들여 제작한 결과물을<br />활용하지 않고 묵혀두고 계시나요?</div>
        <div class="h7 main margin-top-8">예비창업팀에게 공유하고 수익창출하세요</div>
        <img class="margin-top-8" src="/static/icon/img_arrow_circle_right.svg" style="width: 32px; height: 32px" />
        <!-- <div class="flex-center" style="margin-top:32px">
          <button class="button is-primary body2-bold flex-align" style="width:280px;height:48px" @click="routerPush('/template_inquiry')">
            <img src="/static/icon/img_enelope.png" style="width:24px;height:24px;margin-right:6px"
            > 내 소스 파일 판매 신청하기</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CardProduct from '../component/CardProduct';
import SvgIcon from '../component/SvgIcon';
import Slick from 'vue-slick';
import CardProductSimple from '../component/CardProductSimple';

export default {
  name: 'HomeMobile',
  components: {
    CardProductSimple,
    SvgIcon,
    CardProduct,
    Slick,
  },
  created() {
    this.getData();
  },
  activated() {
    this.selectedMenu = 0;
    this.$nextTick(() => {
      this.controller = new AbortController();
      const { signal } = this.controller;
      window.addEventListener('scroll', this.scrollListener, { signal });

      if (this.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      }
    });
  },
  deactivated() {
    this.controller.abort();
  },
  data() {
    return {
      controller: undefined,
      vFixedMenu: false,
      selectedMenu: 0,
      menu: [
        {
          id: 1,
          name: '아이디어 테스트 단계',
          icon: 'light_bulb',
        },
        {
          id: 2,
          name: '시장 선점 단계',
          icon: 'flag',
        },
        {
          id: 3,
          name: '비즈니스 단계',
          icon: 'chart',
        },
        {
          id: 4,
          name: '서비스 런칭 단계',
          icon: 'rocket',
        },
        {
          id: 5,
          name: '인하우스 제작 단계',
          icon: 'tool',
        },
        {
          id: 6,
          name: '비지니스 종료 단계',
          icon: 'octagon',
        },
      ],
      themes: [],
      skins: [],
      plugins: [],
      templates: [],
      slickOptions: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: false,
        centerPadding: '20px',
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        arrows: false,
      },
      currentSlide: 0,
      primes: [
        { id: 2, url: 'https://garamfood.net' },
        { id: 3, url: '' },
        { id: 1, url: '' },
        /*{ id: 1, url: 'https://weflower.co.kr' },*/
      ],
    };
  },
  watch: {
    selectedMenu(n) {
      n--;
      let tabMenu = document.getElementsByClassName('drag-scroll-mobile')[0];
      if (n < this.menu.length / 2) {
        n--;
      } else {
        n++;
      }
      if (n === -1) {
        n = 0;
      } else if (n === 6) {
        n = 5;
      }
      let el = document.getElementById(`fixed-item-${n}`);
      if (el) {
        setTimeout(() => {
          tabMenu.scrollLeft = el.offsetLeft;
        });
      }
    },
  },
  methods: {
    onChange(event, slick, currentSlide) {
      this.currentSlide = currentSlide;
    },
    async getData() {
      let params = {
        category: 59,
        fields:
          'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,params,theme_version',
        page_num: 1,
        page_length: 5,
      };
      let res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.themes = res.data.data;
      // skins
      params.category = 60;
      params.page_length = 3;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.skins = res.data.data;

      params.category = 64;
      params.page_length = 4;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.plugins = res.data.data;

      params.category = 61;
      params.page_length = 3;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.templates = res.data.data;
    },
    clickMenu(item) {
      location.href = '#menu-m-' + item.id;
      setTimeout(() => {
        this.selectedMenu = item.id;
      }, 100);
    },
    scrollListener() {
      if (document.getElementById('menu-m-1')) {
        this.vFixedMenu = document.getElementById('menu-m-1').getBoundingClientRect().y <= 40;

        const html = document.getElementsByTagName('html')[0].getBoundingClientRect();
        if (html.y === 0) {
          this.selectedMenu = 0;
        } else if (html.height + html.y - window.innerHeight < 300) {
          this.selectedMenu = this.menu.length;
        } else {
          const menus = document.querySelectorAll('.menus');
          menus.forEach((el) => {
            let rect = el.getBoundingClientRect();
            if (rect.top < 180 && rect.height + rect.top > 0) {
              this.selectedMenu = Number(el.id.replace('container-', ''));
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.button
  font-size 14px
  font-weight 500

.banner
  background url(/static/img/home/background_main_banner.png)
  background-repeat no-repeat
  background-position 50%
  background-size cover
  padding 68px 0 88px 0
  text-align center

.banner-white-box
  margin-top -20px
  background-color white
  border-top-left-radius 20px
  border-top-right-radius 20px
  padding 28px 20px 12px 20px
  font-size 14px
  line-height 22px
  text-align center


.platformfy-info-container
  background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EFEFEF 100%)
  padding-bottom 50px

.section-menu
  padding 40px 0 24px 0
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.05)

.grid-menu
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 28px
.item-menu
  text-align center

.section
  padding 50px 16px

.box-error-50
  margin-bottom 8px
  display flex
  justify-content center
  align-items center
  background-color $error50
  color $error
  font-size 14px
  padding 10px
  border-radius 50px
  width 100%
  img
    margin-right 12px
  b
    margin-left 4px

.box-primary-light2
  background-color $primary-light2
  color $primary
  padding 12px
  border-radius 8px

  .emoticon
    width 36px
    height 36px

#container-2
  border-top 1px solid $gray1
#container-3
  background-color $main
  position relative
  text-align center
  overflow hidden
.img-prime-back
  position absolute
  top -65px
  left calc(50% - 394px)
  background-image url(/static/img/home/img_prime_back2.png)
  width 740px
  background-size contain
  height 509px
  background-position center
  background-repeat no-repeat
  z-index 0

.slick-img
  height 168px
  width 100%
  background-repeat no-repeat
  background-position center
  background-size contain
.indicators
  display flex
  align-content center
  justify-content center
  gap 12px
  .indicator
    width 6px
    height 6px
    border-radius 100%
    background-color rgba(255, 255, 255, 0.5)
  .indicator-current
    background-color #ffffff


#container-4
  background-color $gray3

.box-app-market-desc
  background-color white
  padding 24px 20px
  border-radius 8px
  word-break keep-all

.menu4-items
  margin-top 28px
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-gap 12px

.menu6
  background-color $subcolor50
  padding 28px 16px
  text-align center

a.anchor
  top -59px
  position absolute

.fixed-menu
  width 100%
  overflow hidden
  position fixed
  top 60px
  left 0
  z-index 10
  background-color white
  border-bottom 1px solid $gray1
.item-fixed-menu
  padding 12px 10px
  border-bottom 3px solid transparent
  scroll-snap-align start
  color $sub
.item-fixed-menu:first-child
  margin-left 16px
.item-fixed-menu:last-child
  margin-right 16px
.item-fixed-menu.selected
  color $primary
  font-weight 500
  border-bottom 3px solid $primary

.drag-scroll-mobile
  white-space nowrap
  overflow-x scroll
  overflow-y hidden
  scroll-snap-type x mandatory

.drag-scroll-mobile::-webkit-scrollbar
  display none
</style>
